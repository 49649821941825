.toolbar {
    left: 50%;
    transform: translate(-50%) scale(0);
    position: absolute;
    background: #FFF;
    border-radius: 6px;
    box-shadow: 0px 0px 16px rgba(41, 51, 61, 0.12);
    z-index: 2;
    box-sizing: border-box;
    padding: 0 4px;
    white-space: nowrap;
}

.toolbar:after,
.toolbar:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.toolbar:after {
    /* border-color: rgba(255, 255, 255, 0);
    border-top-color: #333; */
    border-width: 14px;
    margin-left: -14px;
}

.toolbar:before {
    /* border-color: rgba(221, 221, 221, 0);
    border-top-color: #111; */
    border-width: 16px;
    margin-left: -16px;
}