@import url(https://fonts.googleapis.com/css?family=Material+Icons);
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Quicksand:wght@700&display=swap"); */

iframe {
  pointer-events: none;
}

@font-face {
  font-family: 'Inter';
  src: url(/static/media/Inter-Regular.23917250.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: url(/static/media/Inter-Medium.e7231ba3.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url(/static/media/Inter-Bold.a73db89c.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inconsolata';
  src: url(/static/media/Inconsolata-Regular.54748073.ttf) format('truetype');
  font-weight: 400;
}
/* * {
  margin: 0;
  padding: 0;
} */
/* commented because FAQ HTML styling override */

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #29333d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

p,
h4,
h5 {
  margin: 0;
}

.rg-context-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.rg-context-menu-option {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  height: 16px;
  min-width: 160px;
  font-family: Inter;
  font-style: normal;
  font-size: 12px;
  letter-spacing: 0.4px;
}

.rg-context-menu-option:hover {
  background-color: #e3f3ff;
}

.rg-formula-cell-error {
  color: rgba(222, 41, 76, 1) !important;
  border: 1px solid rgba(222, 41, 76, 1) !important;
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #b3bfcc;
}

.faq-page {
  margin: "initial !important";
  padding: "initial !important";
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
}
.jss_input.jss_formula {
  flex-wrap: wrap;
}

.jss_input.jss_formula b.last {
  width: auto;
}

.jpicker-content {
  max-height: unset;
}

.jss_content>table>tbody>tr>td {
  white-space: initial;
}

.jss_frozen {
  cursor: default !important;
}

.jss > thead > tr > td, 
.jss > tbody > tr > td {
  font-size: 12px;
}

.jss {
  margin-right: -32px !important;
}



.editorStyles_editor__jF8VJ {
    box-sizing: border-box;
    border: 1px solid #D6DFEB;
    cursor: text;
    padding: 4px 8px;
    line-height: 1.5;
    background: #FFF;
    border-radius: 6px;
}

.editorStyles_editor__jF8VJ,
.editorStyles_editor__jF8VJ .public-DraftEditorPlaceholder-root {
    font-size: 12px;
    font-weight: normal;
    width: 100%;
}

.editorStyles_editor__jF8VJ:focus-within {
    border-color: #118CFF !important;
}

.editorStyles_editor__jF8VJ .public-DraftEditor-content {
    overflow-wrap: anywhere !important;
}

.editorStyles_editor__jF8VJ .public-DraftEditorPlaceholder-root {
    color: #b3bfcc;
    position: relative;
    z-index: 0;
}

.editorStyles_editor__jF8VJ .public-DraftEditorPlaceholder-inner {
    position: absolute;
    pointer-events: none;
}

.editorStyles_editor__jF8VJ[data-empty=true] {
    border-color: #DE294C;
}

.editorStyles_editor__jF8VJ[data-error="true"] {
    border-color: #DE294C;
}

.editorStyles_editor__jF8VJ[component="description"] {
    width: 312px;
}

.editorStyles_editor__jF8VJ[component="descriptionField"] {
    width: 388px;
}

.editorStyles_editor__jF8VJ[component="unit"],
.editorStyles_editor__jF8VJ[component="notation"] {
    width: 72px;
}

.editorStyles_editor__jF8VJ[component="descriptionField"] {
    width: 388px;
}

.editorStyles_editor__jF8VJ[component="input"] {
    width: 160px;
}

.editorStyles_editor__jF8VJ[component="options"] {
    border: 1px solid transparent !important;
    padding: 0px 4px;
}

.editorStyles_editor__jF8VJ[component="text"] {
    border: 1px solid transparent !important;
}

.editorStyles_editor__jF8VJ[component="twoValueDescription"] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    word-break: break-word;
}

.editorStyles_editor__jF8VJ[component="properties"] {
    padding-top: 8px;
    padding-bottom: 8px;
}

.editorStyles_editor__jF8VJ[data-text-heading="HEADING_1"][data-empty=false] {
    font-size: 16px;
    font-weight: bold;
}

.editorStyles_editor__jF8VJ[data-text-heading="HEADING_2"][data-empty=false] {
    font-size: 14px;
    font-weight: bold;
}

.editorStyles_editor__jF8VJ[data-text-heading="HEADING_3"][data-empty=false] {
    font-weight: bold;
}

.editorStyles_editor__jF8VJ[data-render-as="text-area"] {
    min-height: 70px;
    word-break: break-word;
}
.buttonStyles_buttonWrapper__AuT-K {
    display: inline-block;
}

.buttonStyles_button__1Pf_s {
    background: #FFF;
    color: #52667A;
    font-size: 14px;
    border: 1px solid #D6DFEB;
    margin: 8px 4px;
    vertical-align: middle;
    height: 28px;
    width: 28px;
    border-radius: 4px;
}

.buttonStyles_button__1Pf_s svg {
    fill: #ddd;
}

.buttonStyles_button__1Pf_s:hover,
.buttonStyles_button__1Pf_s:focus {
    background: #E3F3FF;
    outline: 0;
    /* reset for :focus */
}

.buttonStyles_active__3TP2H {
    /* color: #6a9cc9; */
    font-weight: bold;
}

.buttonStyles_active__3TP2H svg {
    /* fill: #6a9cc9; */
    font-weight: bold;
}
.toolbarStyles_toolbar__2tzvK {
    left: 50%;
    -webkit-transform: translate(-50%) scale(0);
            transform: translate(-50%) scale(0);
    position: absolute;
    background: #FFF;
    border-radius: 6px;
    box-shadow: 0px 0px 16px rgba(41, 51, 61, 0.12);
    z-index: 2;
    box-sizing: border-box;
    padding: 0 4px;
    white-space: nowrap;
}

.toolbarStyles_toolbar__2tzvK:after,
.toolbarStyles_toolbar__2tzvK:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.toolbarStyles_toolbar__2tzvK:after {
    /* border-color: rgba(255, 255, 255, 0);
    border-top-color: #333; */
    border-width: 14px;
    margin-left: -14px;
}

.toolbarStyles_toolbar__2tzvK:before {
    /* border-color: rgba(221, 221, 221, 0);
    border-top-color: #111; */
    border-width: 16px;
    margin-left: -16px;
}
.textEditorStyles_editor__w7dw0 {
    box-sizing: border-box;
    border: 1px solid #D6DFEB;
    cursor: text;
    line-height: 1.5;
    background: #FFF;
    border-radius: 6px;
}

.textEditorStyles_editor__w7dw0,
.textEditorStyles_editor__w7dw0 .public-DraftEditorPlaceholder-root {
    font-size: 12px;
    font-weight: normal;
    width: 100%;
}

.textEditorStyles_editor__w7dw0:focus-within {
    border-color: #118CFF !important;
}

.textEditorStyles_editor__w7dw0 .public-DraftEditor-content {
    overflow-wrap: anywhere !important;
    padding: 4px 8px;
}

.textEditorStyles_editor__w7dw0 .public-DraftEditorPlaceholder-root {
    color: #b3bfcc;
    position: relative;
    z-index: 0;
}

.textEditorStyles_editor__w7dw0 .public-DraftEditorPlaceholder-inner {
    position: absolute;
    pointer-events: none;
}

.textEditorStyles_editor__w7dw0[data-empty=true] {
    border-color: #DE294C;
}

.textEditorStyles_editor__w7dw0[data-error="true"] {
    border-color: #DE294C;
}

.textEditorStyles_editor__w7dw0[component="description"] {
    width: 312px;
}

.textEditorStyles_editor__w7dw0[component="descriptionField"] {
    width: 388px;
}

.textEditorStyles_editor__w7dw0[component="unit"],
.textEditorStyles_editor__w7dw0[component="notation"] {
    width: 72px;
}

.textEditorStyles_editor__w7dw0[component="descriptionField"] {
    width: 388px;
}

.textEditorStyles_editor__w7dw0[component="input"] {
    width: 160px;
}

.textEditorStyles_editor__w7dw0[component="options"] {
    border: 1px solid transparent !important;
    padding: 0px 4px;
}

.textEditorStyles_editor__w7dw0[component="text"] {
    border: 1px solid transparent !important;
}

.textEditorStyles_editor__w7dw0[component="twoValueDescription"] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    word-break: break-word;
}

.textEditorStyles_editor__w7dw0[component="properties"] {
    padding-top: 8px;
    padding-bottom: 8px;
}

.textEditorStyles_editor__w7dw0[data-text-heading="HEADING_1"][data-empty=false] {
    font-size: 16px;
    font-weight: bold;
}

.textEditorStyles_editor__w7dw0[data-text-heading="HEADING_2"][data-empty=false] {
    font-size: 14px;
    font-weight: bold;
}

.textEditorStyles_editor__w7dw0[data-text-heading="HEADING_3"][data-empty=false] {
    font-weight: bold;
}

.textEditorStyles_editor__w7dw0[data-render-as="text-area"] {
    min-height: 70px;
    word-break: break-word;
}
.textButtonStyles_buttonWrapper__3S4jv {
    display: inline-block;
}

.textButtonStyles_button__1_ksx {
    vertical-align: middle;
    height: 32px;
    width: 32px;
    background-color: transparent;
    border: none;
}

.textButtonStyles_button__1_ksx svg {
    /* fill: #ddd; */
    padding-top: 3.25px;
    margin-left: -4px;
}

.textButtonStyles_button__1_ksx:hover,
.textButtonStyles_button__1_ksx:focus {
    background: #E3F3FF;
    outline: 0;
    /* reset for :focus */
}

.textButtonStyles_active__21h-q {
    color: #118CFF;
    font-weight: bold;
    background-color: #E3F3FF;
}

.textButtonStyles_active__21h-q svg {
    /* fill: #6a9cc9; */
    fill: #118CFF;
    font-weight: bold;
    background-color: #E3F3FF;
}
.textEditorToolbarStyles_toolbar__1uUZm {
  background: #FFF;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  z-index: 2;
  box-sizing: border-box;
  padding: 2px 4px;
  white-space: nowrap;
  font-size: 1rem;
  border: 1px 1px 0 1px solid #D6DFEB;
  background-color: #F9F9F9;
}
.rg-context-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.rg-context-menu-option {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  height: 16px;
  min-width: 160px;
  font-family: Inter;
  font-style: normal;
  font-size: 12px;
  letter-spacing: 0.4px;
}

.rg-context-menu-option:hover {
  background-color: #E3F3FF !important;
}
.katex {
    white-space: normal;
    text-align: left;
}

.latexShow {
    text-align: left !important;
    display: inline-block;
}

.katex .base {
    width: auto !important;
    white-space: normal !important;
}


body {
    counter-reset: page;
}

.numbers:after {
    counter-increment: my-sec-counter;
    content: "Section " counter(my-sec-counter) ". ";
}

.page-number:after {
    counter-increment: page;
    content: counter(page);
}

.headerTitle {
    font-family: 'Inter';
    font-weight: bold;
    font-size: 16px;
    max-width: 400px;
    margin: 16px 8px;
    text-align: left;
}

.inline {
    display: flex;
    flex-direction: row;
    font-family: 'Inter';
    flex-basis: 60px;
    font-size: 12px;
    margin-bottom: 8px;
    padding: 0 8px;

}

tbody {
    padding: 8px 56px;
}


@page {
    size: auto;
    margin-bottom: 36px;
    left: 0;
}

@media print {
    img {
        page-break-before: auto;
        page-break-after: auto;
        page-break-inside: avoid;
    }

}

.pageBreak {
    page-break-after: always;
}


#pageFooter {
    display: table-footer-group;
}

#pageFooter:after {
    counter-increment: page;
    content: counter(page);
}

#pageNumber:before {
    counter-increment: page;
    content: "Section " counter(page) ". ";
}



#print-container {
    counter-reset: page;
}

.coba::before {
    counter-increment: page;
    content: "Page ini" counter(page);  
}



.empty-header {
    height: 100px;
    margin-bottom: 18px;
}

.empty-footer {
    height: 50px
}

.headerArea {
    position: fixed;
    height: 90px;
    top: 0;
    width: 100%;
}

.footerArea {
    position: fixed;
    bottom: 0;
    left: 36px;
}

.page-number:before {
    content: "Page: " counter(page);
}

.loading {
  font-size: 16px;
  color: #1676D5;
  line-height: 24px;
  font-weight: normal;
}

.loadingChatHistories {
  font-size: 32px;
  color: #000;
  font-weight: 700;
  width: 40px;
  text-align: center;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026";
  width: 0px;
}

.loadingChatHistories:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026";
  width: 0px;
}


@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}
.lazyImage__img {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 544px;
  height: 362px;
  border-radius: 8px;
  object-fit: fill;
}

.carousel__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.carousel__container {
  display: flex;
}

.carousel__item {
  position: relative;
  flex: 0 0 100%;
  margin-bottom: 12px;
}
