.buttonWrapper {
    display: inline-block;
}

.button {
    background: #FFF;
    color: #52667A;
    font-size: 14px;
    border: 1px solid #D6DFEB;
    margin: 8px 4px;
    vertical-align: middle;
    height: 28px;
    width: 28px;
    border-radius: 4px;
}

.button svg {
    fill: #ddd;
}

.button:hover,
.button:focus {
    background: #E3F3FF;
    outline: 0;
    /* reset for :focus */
}

.active {
    /* color: #6a9cc9; */
    font-weight: bold;
}

.active svg {
    /* fill: #6a9cc9; */
    font-weight: bold;
}