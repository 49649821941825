@import 'katex/dist/katex.min.css';

.katex {
    white-space: normal;
    text-align: left;
}

.latexShow {
    text-align: left !important;
    display: inline-block;
}

.katex .base {
    width: auto !important;
    white-space: normal !important;
}

