.jss_input.jss_formula {
  flex-wrap: wrap;
}

.jss_input.jss_formula b.last {
  width: auto;
}

.jpicker-content {
  max-height: unset;
}

.jss_content>table>tbody>tr>td {
  white-space: initial;
}

.jss_frozen {
  cursor: default !important;
}

.jss > thead > tr > td, 
.jss > tbody > tr > td {
  font-size: 12px;
}

.jss {
  margin-right: -32px !important;
}


