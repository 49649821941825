.toolbar {
  background: #FFF;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  z-index: 2;
  box-sizing: border-box;
  padding: 2px 4px;
  white-space: nowrap;
  font-size: 1rem;
  border: 1px 1px 0 1px solid #D6DFEB;
  background-color: #F9F9F9;
}