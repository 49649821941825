.lazyImage__img {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 544px;
  height: 362px;
  border-radius: 8px;
  object-fit: fill;
}

.carousel__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.carousel__container {
  display: flex;
}

.carousel__item {
  position: relative;
  flex: 0 0 100%;
  margin-bottom: 12px;
}