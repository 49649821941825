.buttonWrapper {
    display: inline-block;
}

.button {
    vertical-align: middle;
    height: 32px;
    width: 32px;
    background-color: transparent;
    border: none;
}

.button svg {
    /* fill: #ddd; */
    padding-top: 3.25px;
    margin-left: -4px;
}

.button:hover,
.button:focus {
    background: #E3F3FF;
    outline: 0;
    /* reset for :focus */
}

.active {
    color: #118CFF;
    font-weight: bold;
    background-color: #E3F3FF;
}

.active svg {
    /* fill: #6a9cc9; */
    fill: #118CFF;
    font-weight: bold;
    background-color: #E3F3FF;
}