body {
    counter-reset: page;
}

.numbers:after {
    counter-increment: my-sec-counter;
    content: "Section " counter(my-sec-counter) ". ";
}

.page-number:after {
    counter-increment: page;
    content: counter(page);
}

.headerTitle {
    font-family: 'Inter';
    font-weight: bold;
    font-size: 16px;
    max-width: 400px;
    margin: 16px 8px;
    text-align: left;
}

.inline {
    display: flex;
    flex-direction: row;
    font-family: 'Inter';
    flex-basis: 60px;
    font-size: 12px;
    margin-bottom: 8px;
    padding: 0 8px;

}

tbody {
    padding: 8px 56px;
}


@page {
    size: auto;
    margin-bottom: 36px;
    left: 0;
}

@media print {
    img {
        page-break-before: auto;
        page-break-after: auto;
        page-break-inside: avoid;
    }

}

.pageBreak {
    page-break-after: always;
}


#pageFooter {
    display: table-footer-group;
}

#pageFooter:after {
    counter-increment: page;
    content: counter(page);
}

#pageNumber:before {
    counter-increment: page;
    content: "Section " counter(page) ". ";
}



#print-container {
    counter-reset: page;
}

.coba::before {
    counter-increment: page;
    content: "Page ini" counter(page);  
}



.empty-header {
    height: 100px;
    margin-bottom: 18px;
}

.empty-footer {
    height: 50px
}

.headerArea {
    position: fixed;
    height: 90px;
    top: 0;
    width: 100%;
}

.footerArea {
    position: fixed;
    bottom: 0;
    left: 36px;
}

.page-number:before {
    content: "Page: " counter(page);
}
