.loading {
  font-size: 16px;
  color: #1676D5;
  line-height: 24px;
  font-weight: normal;
}

.loadingChatHistories {
  font-size: 32px;
  color: #000;
  font-weight: 700;
  width: 40px;
  text-align: center;
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026";
  width: 0px;
}

.loadingChatHistories:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026";
  width: 0px;
}


@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}