.rg-context-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.rg-context-menu-option {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  height: 16px;
  min-width: 160px;
  font-family: Inter;
  font-style: normal;
  font-size: 12px;
  letter-spacing: 0.4px;
}

.rg-context-menu-option:hover {
  background-color: #E3F3FF !important;
}