.editor {
    box-sizing: border-box;
    border: 1px solid #D6DFEB;
    cursor: text;
    padding: 4px 8px;
    line-height: 1.5;
    background: #FFF;
    border-radius: 6px;
}

.editor,
.editor :global(.public-DraftEditorPlaceholder-root) {
    font-size: 12px;
    font-weight: normal;
    width: 100%;
}

.editor:focus-within {
    border-color: #118CFF !important;
}

.editor :global(.public-DraftEditor-content) {
    overflow-wrap: anywhere !important;
}

.editor :global(.public-DraftEditorPlaceholder-root) {
    color: #b3bfcc;
    position: relative;
    z-index: 0;
}

.editor :global(.public-DraftEditorPlaceholder-inner) {
    position: absolute;
    pointer-events: none;
}

.editor[data-empty=true] {
    border-color: #DE294C;
}

.editor[data-error="true"] {
    border-color: #DE294C;
}

.editor[component="description"] {
    width: 312px;
}

.editor[component="descriptionField"] {
    width: 388px;
}

.editor[component="unit"],
.editor[component="notation"] {
    width: 72px;
}

.editor[component="descriptionField"] {
    width: 388px;
}

.editor[component="input"] {
    width: 160px;
}

.editor[component="options"] {
    border: 1px solid transparent !important;
    padding: 0px 4px;
}

.editor[component="text"] {
    border: 1px solid transparent !important;
}

.editor[component="twoValueDescription"] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    word-break: break-word;
}

.editor[component="properties"] {
    padding-top: 8px;
    padding-bottom: 8px;
}

.editor[data-text-heading="HEADING_1"][data-empty=false] {
    font-size: 16px;
    font-weight: bold;
}

.editor[data-text-heading="HEADING_2"][data-empty=false] {
    font-size: 14px;
    font-weight: bold;
}

.editor[data-text-heading="HEADING_3"][data-empty=false] {
    font-weight: bold;
}

.editor[data-render-as="text-area"] {
    min-height: 70px;
    word-break: break-word;
}