/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Quicksand:wght@700&display=swap"); */
@import url('https://fonts.googleapis.com/css?family=Material+Icons');

iframe {
  pointer-events: none;
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url(./assets/fonts/Inter/Inter-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inconsolata';
  src: url(./assets/fonts/Inconsolata/Inconsolata-Regular.ttf) format('truetype');
  font-weight: 400;
}
/* * {
  margin: 0;
  padding: 0;
} */
/* commented because FAQ HTML styling override */

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #29333d;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

p,
h4,
h5 {
  margin: 0;
}

.rg-context-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
}

.rg-context-menu-option {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  height: 16px;
  min-width: 160px;
  font-family: Inter;
  font-style: normal;
  font-size: 12px;
  letter-spacing: 0.4px;
}

.rg-context-menu-option:hover {
  background-color: #e3f3ff;
}

.rg-formula-cell-error {
  color: rgba(222, 41, 76, 1) !important;
  border: 1px solid rgba(222, 41, 76, 1) !important;
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block;
  color: #b3bfcc;
}

.faq-page {
  margin: "initial !important";
  padding: "initial !important";
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
  box-shadow: 0 0 0px 1000px rgb(255, 255, 255) inset;
}